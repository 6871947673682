import { Box, BoxProps } from "@mui/material";
import React, { createContext, useContext, useEffect, useRef, useState } from "react";

interface PaddingContextType {
    topPadding: number;
    bottomPadding: number;
    registerFixedElement: (position: "top" | "bottom", height: number) => void;
    unregisterFixedElement: (position: "top" | "bottom", height: number) => void;
}

const PaddingContext = createContext<PaddingContextType | undefined>(undefined);

export const PaddingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [topPadding, setTopPadding] = useState(0);
    const [bottomPadding, setBottomPadding] = useState(0);

    const registerFixedElement = (position: "top" | "bottom", height: number) => {
        if (position === "top") {
            setTopPadding((prev) => prev + height);
        } else {
            setBottomPadding((prev) => prev + height);
        }
    };

    const unregisterFixedElement = (position: "top" | "bottom", height: number) => {
        if (position === "top") {
            setTopPadding((prev) => Math.max(0, prev - height));
        } else {
            setBottomPadding((prev) => Math.max(0, prev - height));
        }
    };

    return (
        <PaddingContext.Provider value={{ topPadding, bottomPadding, registerFixedElement, unregisterFixedElement }}>
            {children}
        </PaddingContext.Provider>
    );
};

export const usePadding = (): PaddingContextType => {
    let context = useContext(PaddingContext);
    if (!context) {
        throw new Error("usePadding must be used within a PaddingProvider");
        // console.error("usePadding must be used within a PaddingProvider");
        // context = {
        //     bottomPadding: 0,
        //     topPadding: 0,
        //     registerFixedElement: () => { },
        //     unregisterFixedElement: () => { },
        // };
    }
    return context;
};

export const useFixedElement = (position: "top" | "bottom") => {
    const { registerFixedElement, unregisterFixedElement } = usePadding();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            const height = ref.current.offsetHeight;
            registerFixedElement(position, height);

            return () => unregisterFixedElement(position, height);
        }
    }, [registerFixedElement, unregisterFixedElement, position]);

    return ref;
};

type CustomBoxProps = BoxProps & {
    children?: React.ReactNode;
};

export const MainContent: React.FC<CustomBoxProps> = ({ children, sx, ...props }) => {
    const { topPadding, bottomPadding } = usePadding();
    console.log('MainContent:', topPadding, bottomPadding);

    return (
        <Box
            className="main-content"
            sx={{
                paddingTop: `${topPadding}px`,
                paddingBottom: `${bottomPadding}px`,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // paddingLeft: `${extraPadding}px`,
                // paddingRight: `${extraPadding}px`,
                width: "100%",
                height: "100%",
                // // include padding in size
                // // so that width: 100% doesn't overflow
                boxSizing: "border-box",
                ...sx
            }}
            {...props}
        >
                {children}
        </Box >
    );
};