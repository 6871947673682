import { id } from "@instantdb/react";
import { Button, Container, Paper, Stack, Typography } from "@mui/material";
import { db } from "src/instant";
import { DropdownOption } from "./Dropdown";
import { Field, Form, Formik, useFormikContext } from "formik";
import FormTextInput from "./FormTextInput";
import { dataInputValidationSchema, DataInputValues, MeasurementUnit, WasteMeasurementWithMaterial } from "src/schema";
import DataInputDropdowns from "./DataInputDropdowns";
import { useFixedElement } from "src/spacing";
import { EVENT_BOTTOM_NAV_HEIGHT } from "./EventBottomNavigation";
import { getOneLink, useMobileKeyboardOpen } from "src/utils";
import { useCallback, useEffect } from "react";

interface Props {
    eventId: string;
    selectedRow: WasteMeasurementWithMaterial | null,
    setSelectedRow: (id: WasteMeasurementWithMaterial | null) => void,
}

const unitOptions: DropdownOption<MeasurementUnit>[] = [
    { value: 'lb', label: 'lb' },
    { value: 'yd3', label: 'yd^3' },
    { value: 'gal', label: 'gal' },
];
const defaultUnits = unitOptions[0].value;

function InnerForm(props: Props) {
    const { selectedRow } = props;
    const {
        isSubmitting,
        isValid,
        handleSubmit,
        setValues,
        setFieldValue,
        setFieldTouched
    } = useFormikContext<DataInputValues>();

    const isEditing = (selectedRow !== null);

    // Update form values when a new row is selected
    useEffect(() => {
        if (isEditing) {
            const oneMaterial = getOneLink(selectedRow.material);
            const oneCategory = getOneLink(oneMaterial?.category)
            setValues({
                categoryId: oneCategory?.id as string,
                materialId: oneMaterial?.id as string,
                units: selectedRow.units,
                quantity: selectedRow.quantity,
            });
        } else {
            // Reset quantity field when row is de-selected.
            (async () => {
                await setFieldValue('quantity', '');
                await setFieldTouched('quantity', false);
            })().catch(console.error);
        }
    }, [
        isEditing,
        selectedRow,
        setValues,
        setFieldValue,
        setFieldTouched
    ]);

    const editingText = isEditing ? <Typography
        sx={{ color: "gray", marginBottom: "5px" }}
    >
        Editing previous entry

    </Typography> : <></>;

    return <Form autoComplete="off" onSubmit={(ev) => {
        ev.preventDefault();
        handleSubmit();
    }}>
        <Stack direction="column" sx={{ alignItems: "center" }}>
            {editingText}
            <DataInputDropdowns unitOptions={unitOptions} />
            <Stack direction="row">
                <Stack direction="column" sx={{ margin: "10px" }}>
                    <Field
                        autoFocus={true}
                        required={true}
                        autoComplete="off"
                        name="quantity"
                        component={FormTextInput}
                        label="Quantity"
                        aria-autocomplete="none"
                        inputProps={{
                            inputMode: "numeric",
                        }}
                    />
                    {/* TODO: TIMESTAMP */}
                </Stack>
                <Button
                    sx={{
                        height: "56px",
                        margin: "10px",
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isSubmitting || !isValid}
                >
                    Submit
                </Button>
            </Stack>
        </Stack>
    </Form>;
}

export default function DataInputForm(props: Props) {
    console.log("DIF");

    const initialValues = {
        categoryId: "",
        materialId: "",
        units: defaultUnits,
        quantity: "",
    };
    type FormValues = typeof initialValues;

    const { eventId, selectedRow, setSelectedRow } = props;

    const ref = useFixedElement("bottom");
    const isKeyboardOpen = useMobileKeyboardOpen();
    const bottom = isKeyboardOpen ? 0 : EVENT_BOTTOM_NAV_HEIGHT;

    const isEditing = (selectedRow !== null);

    const formHeight = isEditing ? 175 : 165;

    const createNewMeasurement = useCallback(async (values: FormValues) => {
        const measurementId = id();
        await db.transact(db.tx.wasteMeasurements[measurementId].update({
            quantity: parseFloat(values.quantity),
            timestamp: Date.now(),
            units: values.units,
        }).link({
            event: eventId,
            material: values.materialId,
        }))
    }, [eventId]);

    const updateMeasurement = useCallback(async (measurementId: string, values: FormValues) => {
        await db.transact(db.tx.wasteMeasurements[measurementId].update({
            quantity: parseFloat(values.quantity),
            // timestamp: Date.now(),
            units: values.units,
        }).link({
            // event: eventId,
            material: values.materialId,
        }))
    }, []);

    return <Paper
        id="data-input-form-box"
        ref={ref}
        elevation={5}
        sx={{
            // position: "fixed",
            // bottom: keyboardHeight > 0 ? `${keyboardHeight}px` : `${EVENT_BOTTOM_NAV_HEIGHT}px`,
            // bottom: `${EVENT_BOTTOM_NAV_HEIGHT}px`,
            position: "fixed",
            bottom,
            // margin: "auto",
            width: "100%",
            // height: EVENT_DATA_INPUT_FORM_HEIGHT,
            height: formHeight,
            // minHeight: 165,
            background: "white",
            transition: "bottom 0.3s ease-in-out",
            overflow: "hidden",
            zIndex: 9,
            padding: "10px",
        }}
    >
        <Container
            maxWidth="sm"
            sx={{ justifyItems: "center" }}
        >
            <Formik
                initialValues={initialValues}
                validationSchema={dataInputValidationSchema}
                onSubmit={async (values, { setSubmitting, setFieldValue, setFieldTouched }) => {
                    setSubmitting(true);
                    if (selectedRow !== null) {
                        await updateMeasurement(
                            selectedRow.id,
                            values,
                        );
                        setSelectedRow(null);
                    } else {
                        await createNewMeasurement(values);
                    }
                    await setFieldValue('quantity', '');
                    await setFieldTouched('quantity', false);
                    setSubmitting(false);
                }}
            >
                <InnerForm {...props} />
            </Formik>
        </Container>
    </Paper>
}