import MapMaterialIcon from "@mui/icons-material/Map";
import ListIcon from "@mui/icons-material/List";
import DescriptionIcon from "@mui/icons-material/Description";
import BarChartIcon from "@mui/icons-material/BarChart";

import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import { useMobileKeyboardOpen } from "src/utils";
import { Link } from "react-router-dom";
import { useFixedElement } from "src/spacing";

export const EVENT_BOTTOM_NAV_HEIGHT = 56;

interface Props {
    activeTabId: number
    routePrefix: string
    eventId: string
}

export default function EventBottomNavigation(props: Props) {
    const { activeTabId, routePrefix, eventId } = props;
    const ref = useFixedElement("bottom");
    const mobileKeyboardOpen = useMobileKeyboardOpen();

    if (mobileKeyboardOpen) {
        return <></>
    }

    return <Paper
        ref={ref}
        elevation={3}
        style={{
            position: "fixed",
            bottom: 0,
            width: "100vw",
            height: EVENT_BOTTOM_NAV_HEIGHT,
            zIndex: 10
        }}

    >
        <BottomNavigation showLabels value={activeTabId}>
            <BottomNavigationAction label="Map" icon={<MapMaterialIcon />} component={Link} to={`${routePrefix}/${eventId}/map`} />
            <BottomNavigationAction label="Inventory" icon={<ListIcon />} component={Link} to={`${routePrefix}/${eventId}/inventory`} />
            <BottomNavigationAction label="Notes" icon={<DescriptionIcon />} component={Link} to={`${routePrefix}/${eventId}/notes`} />
            <BottomNavigationAction label="Data" icon={<BarChartIcon />} component={Link} to={`${routePrefix}/${eventId}/data`} />
        </BottomNavigation>
    </Paper>;
}