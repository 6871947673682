import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { db } from "../instant";

interface Props {
    measurementId?: string,
    open: boolean,
    onDelete?: () => void,
    onClose: () => void,
}

export default function DeleteMeasurementDialog(props: Props) {
    const { measurementId } = props;

    if (measurementId === undefined) {
        return <></>
    }

    const deleteAndClose = async () => {
        console.log('deleting', measurementId);
        await db.transact([
            db.tx.wasteMeasurements[measurementId].delete(),
        ]);

        if (props.onDelete !== undefined) {
            props.onDelete();
        }
        props.onClose();
    }

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really delete this entry?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The selected entry will be deleted.
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button onClick={deleteAndClose} color="error">Delete</Button>
        </DialogActions>
    </Dialog>
}