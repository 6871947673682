import { Box } from "@mui/material";
import EventsProvider from "../components/EventsProvider";
import NewVersionDialog from "../components/NewVersionDialog";
import { PaddingProvider } from "src/spacing";

interface Props {
    children: JSX.Element | JSX.Element[]
}

export default function BaseLayout(props: Props) {
    return <Box id="base-layout" sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: "hidden",
    }}>
        <EventsProvider>
            <PaddingProvider>
                {props.children}
            </PaddingProvider>
        </EventsProvider>
        <NewVersionDialog />
    </Box>
}