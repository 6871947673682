import { i } from "@instantdb/core";
const _schema = i.schema({
    // We inferred 25 attributes!
    // Take a look at this schema, and if everything looks good,
    // run `push schema` again to enforce the types.
    entities: {
        // $files: i.entity({
        //   "content-disposition": i.string().indexed(),
        //   "content-type": i.string().indexed(),
        //   "key-version": i.number(),
        //   metadata: i.string(),
        //   path: i.string().indexed(),
        //   size: i.number().indexed(),
        //   status: i.string().indexed(),
        //   url: i.string(),
        // }),
        $users: i.entity({
            email: i.string().unique().indexed(),
        }),
        authorizedUsers: i.entity({
            email: i.string(),
        }),
        events: i.entity({
            bgImageUrl: i.string(),
            name: i.string(),
            notes: i.string(),
        }),
        latestVersion: i.entity({
            git_commit: i.string(),
        }),
        mapPins: i.entity({
            cleanedUp: i.boolean(),
            notes: i.string(),
            x: i.number(),
            y: i.number(),
        }),
        maps: i.entity({
            bgImageUrl: i.string(),
            name: i.string(),
            order: i.number().indexed(),
        }),
        stationTypes: i.entity({
            black: i.number(),
            blue: i.number(),
            compostBucket: i.number(),
            description: i.string(),
            green: i.number(),
            iconName: i.string(),
            name: i.string(),
            order: i.number().unique().indexed(),
            slimJim: i.number(),
        }),
        wasteMeasurements: i.entity({
            quantity: i.number(),
            units: i.string(),
            timestamp: i.date(),
        }),
        wasteCategories: i.entity({
            name: i.string(),
            order: i.number().unique().indexed(),
        }),
        wasteMaterials: i.entity({
            name: i.string(),
            order: i.number().indexed(),
        })
    },
    links: {
        eventsMaps: {
            forward: {
                on: "events",
                has: "many",
                label: "maps",
            },
            reverse: {
                on: "maps",
                has: "one",
                label: "event",
            },
        },
        mapPinsStationTypes: {
            forward: {
                on: "mapPins",
                has: "one",
                label: "stationType",
            },
            reverse: {
                on: "stationTypes",
                has: "many",
                label: "mapPins",
            },
        },
        mapsMapPins: {
            forward: {
                on: "maps",
                has: "many",
                label: "pins",
            },
            reverse: {
                on: "mapPins",
                has: "one",
                label: "map",
            },
        },
        wasteMaterialsWasteCategories: {
            forward: {
                on: "wasteMaterials",
                has: "one",
                label: "category",
            },
            reverse: {
                on: "wasteCategories",
                has: "many",
                label: "materials",
            },
        },
        wasteMeasurementsWasteMaterials: {
            forward: {
                on: "wasteMeasurements",
                has: "one",
                label: "material",
            },
            reverse: {
                on: "wasteMaterials",
                has: "many",
                label: "measurements",
            },
        },
        wasteMeasurementsEvents: {
            forward: {
                on: "wasteMeasurements",
                has: "one",
                label: "event",
            },
            reverse: {
                on: "events",
                has: "many",
                label: "measurements",
            },
        },
    },
    // If you use presence, you can define a room schema here
    // https://www.instantdb.com/docs/presence-and-topics#typesafety
    rooms: {},
});
const schema = _schema;
export default schema;
