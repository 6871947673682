import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import MapOptionsMenu from "./MapOptionsMenu";
import MapPagination from "./MapPagination";
import EventMap from "./EventMap";
import { useContext, useState } from "react";
import MapIconDrawer from "./MapIconDrawer";
import { MapContext, ViewOnlyContext, useEvent } from "../context";
import { StationType } from "../schema";

interface Props {
    eventId: string
}

export default function EventMapContainer(props: Props) {
    const viewOnly = useContext(ViewOnlyContext);
    const [editMode, setEditMode] = useState(false);
    const [mapNumber, setMapNumber] = useState(0);
    const [cleanupMode, setcleanupMode] = useState(false);
    const [currentStation, setCurrentStation] = useState<StationType | null>(null);

    const { eventId } = props;
    const event = useEvent(eventId);
    const maps = event?.maps || [];
    const map = maps[mapNumber];

    // Automatically open bottom drawer in edit mode
    const drawerOpen = editMode;

    const toggleEditMode = () => {
        // only allow edit mode to be enabled
        // when view-only mode is not active.
        setEditMode(!viewOnly && !editMode);
        setCurrentStation(null);
    }

    const toggleCleanupMode = () => {
        setcleanupMode(!cleanupMode);
    }

    const mapContext = {
        editMode,
        toggleEditMode,
        cleanupMode,
        toggleCleanupMode,
        currentStation,
        setCurrentStation,
        eventId,
        mapNumber,
    };

    return <MapContext.Provider value={mapContext}>
        <Box sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            zIndex: 0,
        }}>
            <Box sx={{
                width: "100%",
                overflowX: "auto",
            }}>
                <Typography variant="h4" sx={{
                    textAlign: "center",
                    whiteSpace: "nowrap",
                    margin: "auto",
                }}>
                    {map?.name}
                </Typography>
            </Box>
            <Box sx={{ flex: 1, position: "relative" }}>
                <EventMap mapNumber={mapNumber} />
                <MapOptionsMenu />
                {maps.length > 1 && <MapPagination
                    numPages={maps.length}
                    currentPage={mapNumber}
                    setPage={setMapNumber}
                />}
            </Box>

            <MapIconDrawer
                open={drawerOpen}
                currentStation={currentStation}
                setCurrentStation={setCurrentStation}
            />
        </Box>
    </MapContext.Provider>
}