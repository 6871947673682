import { IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { db } from "src/instant";
import { getOneLink } from "src/utils";
import LoadingIndicator from "./LoadingIndicator";
import { useCallback, useContext, useEffect, useState } from "react";
import { ScrollContext } from "src/context";
import { WasteMeasurementWithMaterial } from "src/schema";
import DeleteMeasurementDialog from "./DeleteMeasurementDialog";

interface Props {
    eventId: string
    selectedRow: WasteMeasurementWithMaterial | null,
    setSelectedRow: (id: WasteMeasurementWithMaterial | null) => void,
}

const useArrayGrowth = <T,>(array: T[], callback: () => void) => {
    const [prevLength, setPrevLength] = useState(array.length);

    useEffect(() => {
        if (array.length > prevLength) {
            callback();
        }
        setPrevLength(array.length);
    }, [array.length, prevLength, callback]);
};

export default function DataViewTable(props: Props) {
    const { eventId, selectedRow, setSelectedRow } = props;
    const { data, isLoading, error } = db.useQuery({
        wasteMeasurements: {
            $: {
                where: {
                    'event.id': eventId,
                },
            },
            material: {
                category: {},
            },
        },
    });

    const handleClickRow = useCallback((clickedRow: WasteMeasurementWithMaterial) => {
        if (clickedRow.id === selectedRow?.id) {
            // deselect on second click
            setSelectedRow(null);
        } else {
            setSelectedRow(clickedRow);
        }
    }, [selectedRow, setSelectedRow]);

    // Scroll to bottom when a new measurement is added
    const { scrollToBottom } = useContext(ScrollContext);
    const wasteMeasurements = data?.wasteMeasurements || [];
    useArrayGrowth(wasteMeasurements, scrollToBottom);

    const [dialogOpen, setDialogOpen] = useState(false);
    const [measurementToDelete, setMeasurementToDelete] = useState<string | undefined>(undefined);

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false);
    }, [setDialogOpen]);

    const handleClickDelete = useCallback((measurementId: string) => {
        setMeasurementToDelete(measurementId);
        setDialogOpen(true);
    }, [setMeasurementToDelete, setDialogOpen])

    const onDelete = useCallback(() => {
        // deselect if this row was selected
        if (selectedRow?.id === measurementToDelete) {
            setSelectedRow(null);
        }
    }, [selectedRow, setSelectedRow, measurementToDelete])

    if (isLoading) {
        return <LoadingIndicator message="Loading event data..." />;
    }

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    return <>
        <DeleteMeasurementDialog
            measurementId={measurementToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
            onDelete={onDelete}
        />
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Material</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>Timestamp</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {wasteMeasurements.map((measurement, key) => {
                    const oneMaterial = getOneLink(measurement.material);
                    const oneCategory = getOneLink(oneMaterial?.category)
                    const date = new Date(measurement.timestamp);
                    const dateFormatted = date.toLocaleString('en-US', {
                        hour: 'numeric',
                        minute: '2-digit',
                        hour12: true,
                        month: 'numeric',
                        day: 'numeric',
                        year: '2-digit'
                    });
                    const isSelected = (selectedRow?.id === measurement.id);
                    const backgroundColor = isSelected ? "#f0f0f0" : "inherit";
                    return <TableRow
                        key={key}
                        onClick={() => { handleClickRow(measurement) }}
                        sx={{
                            cursor: "pointer",
                            backgroundColor,
                        }}
                    >
                        <TableCell>
                            <IconButton
                                onClick={(ev) => {
                                    ev.stopPropagation();
                                    handleClickDelete(measurement.id)
                                }}
                                edge="end"
                                aria-label="delete"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </TableCell>
                        <TableCell>{oneCategory?.name}</TableCell>
                        <TableCell>{oneMaterial?.name}</TableCell>
                        <TableCell>{measurement.quantity}&nbsp;{measurement.units}</TableCell>
                        <TableCell>{dateFormatted}</TableCell>
                    </TableRow>;
                })}
            </TableBody>
        </Table>
    </>;
}