import { Box } from "@mui/material";
import DefaultLayout from "./DefaultLayout";

interface Props {
    children: React.ReactNode,
}

export default function ScrollableLayout(props: Props) {
    return <DefaultLayout>
        <Box
            sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                boxSizing: "border-box",
                overflowY: "auto",
                overflowX: "hidden",
            }}>
            <>{props.children}</>
        </Box>
    </DefaultLayout>
}