import { IconButton, List, ListItem, ListItemButton, ListItemText, Paper, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import DeleteEventDialog from "./DeleteEventDialog";
import { Event } from "../schema";
import { useEventsList } from "../context";

export default function EventsList() {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [eventToDelete, setEventToDelete] = useState<Event | undefined>(undefined);
    const navigate = useNavigate();
    const events = useEventsList();

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleClickDelete = (event: Event) => {
        setEventToDelete(event);
        setDialogOpen(true);
    }

    return <>
        <DeleteEventDialog
            event={eventToDelete}
            open={dialogOpen}
            onClose={handleCloseDialog}
        />
        <Paper sx={{ minWidth: "300px", width: "100%", maxWidth: "400px", margin: "20px" }} elevation={4}>
            <Typography variant="h2" sx={{ textAlign: "center" }}>Events</Typography>
            <List style={styles.eventsList}>
                {events.map((event, key) =>
                    <ListItem disablePadding style={styles.eventItem} key={key} secondaryAction={
                        <IconButton
                            onClick={() => handleClickDelete(event)}
                            edge="end"
                            aria-label="delete"
                        >
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemButton onClick={() => navigate(`/event/${event.id}`)}>
                            <ListItemText>
                                {event.name}
                            </ListItemText>
                        </ListItemButton>
                    </ListItem >
                )}
            </List>
        </Paper>
    </>;
}

const styles: Record<string, React.CSSProperties> = {
    eventsList: {},
    eventItem: {},
};