import { useMobileKeyboardOpen } from "src/utils";

interface Props {
    children: React.ReactNode
}

export default function UnlessKeyboard(props: Props) {
    const isKeyboardOpen = useMobileKeyboardOpen();
    if (isKeyboardOpen) {
        return <></>;
    } else {
        return <>{props.children}</>;
    }
}