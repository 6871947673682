import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { db } from "../instant";
import { InstaQLEntity, tx } from "@instantdb/react";
import { AppSchema } from "@zw-app/shared/instant.schema";

type Event = InstaQLEntity<AppSchema, 'events', { maps: { pins: {} } }>

interface Props {
    event?: Event,
    open: boolean,
    onClose: () => void,
}

export default function DeleteEventDialog(props: Props) {
    const { user } = db.useAuth();

    if (props.event === null) {
        return <></>
    }

    const deleteAndClose = async () => {
        if (props.event) {
            const maps = props.event?.maps || [];
            const pins = maps.flatMap(map => map.pins).filter(pin => !!pin);

            // NOTE: Not actually deleting images for now

            // Delete maps for this event
            const deleteMaps = maps.map(
                map => tx.maps[map.id].delete()
            );

            // Delete map pins for this event
            const deletePins = pins.map(
                pin => tx.mapPins[pin.id].delete()
            );

            await db.transact([
                tx.events[props.event.id].delete(),
                ...deleteMaps,
                ...deletePins,
            ]);

            props.onClose();
        }
    }

    const eventName = props.event?.name || "<UNDEFINED>"

    return <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>
            Really delete this event?
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                The event "{eventName}" will be deleted.
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onClose} autoFocus>Cancel</Button>
            <Button disabled={!user} onClick={deleteAndClose} color="error">Delete</Button>
        </DialogActions>
    </Dialog>
}