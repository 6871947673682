import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import LoadingIndicator from "./LoadingIndicator";
import { db } from "../instant";
import { LATEST_VERSION_ID } from "../constants";
import { useState } from "react";

const CURRENT_COMMIT = process.env.REACT_APP_GIT_COMMIT;
const NODE_ENV = process.env.NODE_ENV;

export default function NewVersionDialog() {
    const [open, setOpen] = useState(false);
    const [dismissed, setDismissed] = useState(false);
    const { data, isLoading, error } = db.useQuery({
        latestVersion: {
            $: {
                where: {
                    id: LATEST_VERSION_ID
                }
            }
        }
    });

    if (NODE_ENV !== 'production') {
        return <></>;
    }

    if (isLoading) {
        return <LoadingIndicator message="Loading version info..." />;
    }

    if (error) {
        return <Typography>Oh no! {JSON.stringify(error)}</Typography>;
    }

    const { latestVersion } = data;

    if (latestVersion.length !== 1) {
        return <Typography>error getting latest version info</Typography>
    }

    const latestCommit = latestVersion[0].git_commit;

    const isOutdated = (CURRENT_COMMIT !== latestCommit);

    // Auto-open dialog when app is outdated
    if (isOutdated && !dismissed && !open) {
        setOpen(true);
    }

    const onClose = () => {
        setOpen(false);
        setDismissed(true);
    }

    return <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            An update is available!
        </DialogTitle>

        <DialogContent>
            <DialogContentText>
                <>
                    You aren't using the latest version of the app!
                    You're using #{CURRENT_COMMIT}, but #{latestCommit} is now available.

                    Try refreshing the page and/or reopening the app to update.
                </>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button color="error" onClick={onClose}>Dismiss</Button>
        </DialogActions>
    </Dialog>

}