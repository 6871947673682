import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FieldProps } from "formik";

export interface DropdownOption<T> {
    label: string
    value: T
}

interface Props<T> {
    options: DropdownOption<T>[],
    label: string,
    // value: T,
    // disabled?: boolean,
    // handleChange: (...args: any[]) => void,
}

export default function Dropdown<T extends string | number>(props: FieldProps & Props<T>) {
    const {
        form,
        field,
        meta,
        options,
        label,
        // disabled,
        // handleChange,
        ...otherProps
    } = props;

    return <FormControl sx={{ margin: "5px" }} >
        <InputLabel id={`${field.name}-label`}>{label}</InputLabel>
        <Select
            id={field.name}
            labelId={`${field.name}-label`}
            label={label}
            name={field.name}
            value={props.field.value}
            onChange={props.field.onChange}
            onBlur={props.field.onBlur}
            error={props.form.touched[field.name] && Boolean(props.form.errors[field.name])}
            {...otherProps}
        >
            {
                options.map(opt =>
                    <MenuItem
                        key={opt.value}
                        value={opt.value}>
                        {opt.label}
                    </MenuItem>
                )
            }
        </Select>
    </FormControl >
}