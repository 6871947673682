import { MainContent } from "src/spacing";
import Navbar from "../components/Navbar";
import BaseLayout from "./BaseLayout";
import { Box } from "@mui/material";
import UnlessKeyboard from "src/components/UnlessKeyboard";

interface Props {
    children: React.ReactNode;
}

export default function DefaultLayout(props: Props) {
    return <BaseLayout>
        <Box id="layout-relative"
            sx={{
                position: "relative",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
            }}
        >
            <UnlessKeyboard>
                <Navbar />
            </UnlessKeyboard>
            <MainContent>
                <>{props.children}</>
            </MainContent>
        </Box>
    </BaseLayout>
}