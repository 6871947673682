import { InstaQLEntity, InstaQLResult } from "@instantdb/react";
import * as Yup from "yup";
import { AppSchema } from "@zw-app/shared/instant.schema";
import convert from "convert-units";

const stationType = {};
const pins = { stationType };
const maps = { pins };
const events = { maps };

export type Event = InstaQLEntity<AppSchema, 'events', typeof events>;
export type EventMap = InstaQLEntity<AppSchema, 'maps', typeof maps>;
export type MapPin = InstaQLEntity<AppSchema, 'mapPins', typeof pins>;
export type StationType = InstaQLEntity<AppSchema, 'stationTypes', typeof stationType>;
export type MeasurementUnit = InstaQLEntity<AppSchema, 'wasteMeasurements'>['units'];

const massUnits = convert().possibilities("mass");
const volumeUnits = convert().possibilities("volume");
const validUnits = [...massUnits, ...volumeUnits] as MeasurementUnit[];
export const dataInputValidationSchema = Yup.object({
    quantity: Yup.number()
        .typeError("Quantity must be a valid number")
        .required("Quantity is required"),
    units: Yup.string()
        .oneOf(validUnits, "Invalid unit")
        .required("Units are required"),
    categoryId: Yup.string()
        .required("Category is required"),
    materialId: Yup.string()
        .required("Category is required"),
});

export type DataInputValues = Yup.InferType<typeof dataInputValidationSchema>;

export type WasteMeasurementWithMaterial = InstaQLResult<AppSchema, {
    wasteMeasurements: {
        material: {
            category: {},
        },
    }
}>['wasteMeasurements'][number]