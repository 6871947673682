import { Field, Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { db } from '../instant';
import { id, tx } from '@instantdb/react';
import { Button, Paper, Stack, Typography } from '@mui/material';
import FormTextInput from './FormTextInput';
import { useState } from 'react';
import LoadingIndicator from './LoadingIndicator';
import { CreateMap, bgImageKey, createMaps, newEmptyMap } from '../upload';
import AddNewMaps from './AddNewMaps';

// FieldArray info:
// - https://deaconn.net/blog/view/react-form-with-dynamic-fields-and-formik
// - https://formik.org/docs/api/fieldarray

interface Values {
    eventName: string
    maps: CreateMap[]
}

export default function CreateEventForm() {
    const navigate = useNavigate();
    const { user, isLoading, error } = db.useAuth();
    const [isUploading, setIsUploading] = useState(false);

    if (isLoading) {
        return <div>Authenticating...</div>
    }
    if (error) {
        return <div>Error authenticating: {error.message}</div>
    }

    const initialValues: Values = {
        eventName: "",
        maps: [newEmptyMap()],
    }

    const maybeUploadSpinner = isUploading ? <LoadingIndicator message="Creating event..." /> : <></>;

    return <Paper sx={{ position: "relative", minWidth: "300px", width: "100%", maxWidth: "400px", margin: "20px", padding: 2 }} elevation={4}>
        <Typography variant="h4" sx={{ textAlign: "center" }}>Create a new event</Typography>
        <Formik
            initialValues={initialValues}
            validate={values => {
                const errors: any = {};

                // Check event name
                if (!values.eventName) {
                    errors.eventName = 'Required';
                }

                // Check background images
                values.maps.forEach((map, index) => {
                    const key = bgImageKey(index);
                    if (!map.bgImage) {
                        errors[key] = 'Required';
                    }
                });

                return errors;
            }}

            onSubmit={async (values, { setSubmitting }) => {
                const eventId = id();

                console.log('submit values', values);

                const token = user?.refresh_token;
                if (!token) {
                    throw new Error('no token!');
                }

                const otherTxns = [
                    // Create event
                    tx.events[eventId].update({
                        name: values.eventName,
                    })
                ];

                const params = {
                    maps: values.maps,
                    eventId,
                    otherTxns,
                    token,
                    setIsUploading,
                    setSubmitting,
                    navigate,
                };

                await createMaps(params);
            }}
        >
            {({ values, isSubmitting }) => (
                <Form>
                    <Stack direction="column" spacing={1}>
                        <Field autoFocus={true} required={true} name="eventName" component={FormTextInput} label="Event name" />
                        <br />

                        <AddNewMaps
                            maps={values.maps}
                            isSubmitting={isSubmitting}
                        />
                        <br />

                        <Button
                            sx={{
                                position: "absolute",
                                bottom: "16px",
                                right: "16px"
                            }}
                            type="submit"
                            disabled={isSubmitting}
                        >
                            Create Event
                        </Button>
                    </Stack>
                </Form>
            )}
        </Formik>
        {maybeUploadSpinner}
    </Paper >
}