import { TextField } from "@mui/material";
import { FieldProps } from "formik";

// interface Props {
//     ref?: React.RefObject<HTMLInputElement>
// }

export default function FormTextInput(props: FieldProps) {
    const { form, field, meta, ...otherProps } = props;
    console.log('OTHER PROPS', otherProps);
    return <TextField
        fullWidth
        id={field.name}
        name={field.name}
        value={field.value}
        onChange={(ev: React.ChangeEvent<any>) => {
            form.setFieldTouched(field.name).catch(console.error)
            field.onChange(ev);
        }}
        onBlur={field.onBlur}
        error={form.touched[field.name] && Boolean(form.errors[field.name])}
        helperText={(form.touched[field.name] && form.errors[field.name]) as string}
        {...otherProps}
    />
}