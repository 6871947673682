import { createContext, useContext } from "react";
import { Event, EventMap, StationType } from "./schema";

interface IMapContext {
    editMode: boolean
    toggleEditMode: () => void
    cleanupMode: boolean
    toggleCleanupMode: () => void
    currentStation: StationType | null
    setCurrentStation: (icon: StationType | null) => void
    eventId: string | undefined
    mapNumber: number
}

const defaultSetCurrentIcon = () => console.error("OOPS: setCurrentStation undefined");
export const MapContext = createContext<IMapContext>({
    editMode: false,
    toggleEditMode: () => { },
    cleanupMode: false,
    toggleCleanupMode: () => { },
    currentStation: null,
    setCurrentStation: defaultSetCurrentIcon,
    eventId: undefined,
    mapNumber: 0,
})

interface IEventsContext {
    events: Map<string, Event>,
}

interface IScrollContext {
    scrollToBottom: () => void,
}

export const EventsContext = createContext<IEventsContext>({
    events: new Map(),
});

export const ScrollContext = createContext<IScrollContext>({
    scrollToBottom: () => {}
});

export const ViewOnlyContext = createContext<boolean>(false);

export const useEvent = (eventId?: string): Event | undefined => {
    const { events } = useContext(EventsContext);
    if (eventId) {
        const event = events.get(eventId);
        return event;
    }
}

export const useEventMap = (eventId: string | undefined, mapNumber: number): EventMap | undefined => {
    const event = useEvent(eventId);
    const maps = event?.maps || [];
    return maps[mapNumber];
}

export const useEventsList = (): Event[] => {
    const { events } = useContext(EventsContext);
    // convert map to array
    const eventsList = Array.from(events, ([k, v]) => v);
    return eventsList;
}