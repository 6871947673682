import { useMatch } from "react-router-dom"
import { Box } from "@mui/material";
import EventInventory from "../components/EventInventory";
import EventNotes from "../components/EventNotes";
import { ScrollContext, ViewOnlyContext } from "../context";
import EventMapHeader from "../components/EventMapHeader";
import EventMapContainer from "../components/EventMapContainer";
import EventData from "src/components/EventData";
import { useRef } from "react";
import DefaultLayout from "src/layouts/DefaultLayout";
import EventBottomNavigation from "src/components/EventBottomNavigation";
import UnlessKeyboard from "src/components/UnlessKeyboard";

interface Props {
    viewOnly?: boolean
}

export default function EventScreen(props: Props) {
    const viewOnly = props.viewOnly || false;
    const routePrefix = viewOnly ? '/viewevent' : '/event';
    const routeTemplate = `/${routePrefix}/:eventId/:activeTab`;

    const scrollRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        const container = scrollRef.current;
        if (!container) return;
        container.scrollTop = container.scrollHeight;
    };

    const routeMatch = useMatch(routeTemplate);
    if (!routeMatch) {
        return <p>ERROR: unknown route</p>
    }

    const { eventId, activeTab } = routeMatch.params;

    if (!eventId) {
        return <p>ERROR: event id</p>
    }

    if (!activeTab) {
        return <p>ERROR: activeTab</p>
    }

    const tabs = ["map", "inventory", "notes", "data"];
    const activeTabId = tabs.indexOf(activeTab);

    return <ViewOnlyContext.Provider value={viewOnly}>
        <DefaultLayout>
            <Box
                id="event-content"
                ref={scrollRef}
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    overflowY: "auto",
                    height: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                    alignItems: "center",
                }}
            >
                <ScrollContext.Provider value={{ scrollToBottom }} >
                    <EventMapHeader eventId={eventId} />
                    <Box
                        id="event-inner-content"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            flexGrow: 1,
                            width: "100%",
                            boxSizing: "border-box",
                            alignItems: "center",
                        }} >
                        {activeTab === "map" && <EventMapContainer eventId={eventId} />}
                        {activeTab === "inventory" && <EventInventory eventId={eventId} />}
                        {activeTab === "notes" && <EventNotes eventId={eventId} />}
                        {activeTab === "data" && <EventData eventId={eventId} />}
                    </Box>
                </ScrollContext.Provider>
            </Box>
            <UnlessKeyboard>
                <EventBottomNavigation
                    activeTabId={activeTabId}
                    routePrefix={routePrefix}
                    eventId={eventId}
                />
            </UnlessKeyboard>
        </DefaultLayout>
    </ViewOnlyContext.Provider >
}