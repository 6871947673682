import { Drawer, Typography } from "@mui/material";
import MapIconSelector from "./MapIconSelector";
import { StationType } from "../schema";
import { db } from "../instant";
import { EVENT_BOTTOM_NAV_HEIGHT } from "./EventBottomNavigation";

interface Props {
    open: boolean,
    currentStation: StationType | null,
    setCurrentStation: (station: StationType | null) => void
}

export default function MapIconDrawer(props: Props) {
    const helpText = props.currentStation === null ?
        "Select a station to add to the map" :
        "Click on the map to place the selected station";

    const { isLoading, error, data } = db.useQuery({ stationTypes: {} })

    if (isLoading) {
        console.log('loading stations...');
        return <></>
    }
    if (error) {
        console.error('error loading stations: ', error);
        return <div>Error fetching icons: {error.message}</div>
    }

    const { stationTypes } = data;
    stationTypes.sort((a, b) => a.order - b.order);
    console.log('got stations ', stationTypes);

    return <Drawer
        id="map-icon-drawer"
        anchor="bottom"
        variant="persistent"
        open={props.open}
        sx={{ zIndex: 1000 }}
        PaperProps={{
            style: {
                bottom: `${EVENT_BOTTOM_NAV_HEIGHT}px`, // Height of Bottom Navigation
            },
        }}
    >
        <Typography sx={{ margin: 2, marginBottom: 0 }}>{helpText}</Typography>
        <MapIconSelector
            stations={stationTypes}
            currentStation={props.currentStation}
            setCurrentStation={props.setCurrentStation}
        />
    </Drawer>
}
