import { Box, Container, Paper } from "@mui/material";
import DataInputForm from "./DataInputForm";
import DataViewTable from "./DataViewTable";
import { useState } from "react";
import { WasteMeasurementWithMaterial } from "src/schema";

interface Props {
    eventId: string;
}

export default function EventData(props: Props) {
    const [selectedRow, setSelectedRow] = useState<WasteMeasurementWithMaterial | null>(null);

    return <Box
        id="event-data"
        sx={{ width: "100%", height: "100%" }}
    >
        <Container
            maxWidth="sm"
            id="data-view-table-container"
            sx={{
                display: "flex",
                flexGrow: 1,
                flexDirection: "column",
                alignItems: "center",
                paddingBottom: "20px",
                overflow: "auto",
            }}
        >
            <Paper
                id="data-view-table-container"
                sx={{
                    width: "100%",
                    overflow: "auto",
                }}
            >
                <DataViewTable
                    eventId={props.eventId}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                />
            </Paper >
        </Container>
        <DataInputForm
            eventId={props.eventId}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
        />
    </Box>
}